.sticky-notes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center vertically */
  height: 100%;
  /* Adjust height as needed */
  padding: 20px;
}

.sticky-notes {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.sticky-note {
  /* width: 300px;
  height: 200px; */
  padding: 20px;
  /* background-color: #fff; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.sticky-note-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center; /* Align text to center */
  white-space: pre-line; /* Preserve line breaks */
}


h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Optional styles for a slick look */
.sticky-note-container {
  background-color: #f2f2f2;
}

.sticky-note {
  transition: all 0.3s ease;
}

.sticky-note:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}